/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Zastosowanie produktu"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "izolacje i wyrównanie posadzek, stropów, stropodachów o grubości zasypek 3-9 cm"), "\n", React.createElement(_components.li, null, "lekkie betony"), "\n"), "\n", React.createElement(_components.h2, null, "Opakowania"), "\n", React.createElement(_components.p, null, "Sprzedaż w big-bagach po 1 m", React.createElement("sup", null, "3"), " keramzytu w big bagu."), "\n", React.createElement(_components.h2, null, "Dane techniczne"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Wyrób zgodny z:"), " EN 14063-1"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Frakcja:"), " 4-10 mm"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Gęstość nasypowa w stanie luźnym:"), " 272-368 kg/m", React.createElement("sup", null, "3"), " (średnio ok. 320 kg/m", React.createElement("sup", null, "3"), ")"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Współczynnik przewodzenia ciepła:"), " ok. 0,100 W/mK"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Reakcja na ogień:"), " klasa A1 (niepalny)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
